<template>
  <div class="lend-info">
    <el-container class="digit">
      <el-main class="main-content">
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title"
            >借出信息</el-header
          >
          <el-main>
            <el-row :gutter="16">
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="借出总金额"
                  :num="dataForm.lendAllMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="待收金额"
                  :num="dataForm.receiveMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="次"
                  title="借出数量"
                  :num="dataForm.countAllByLend"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="个"
                  title="申请延期数量"
                  :num="dataForm.countByApplyDelay"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="个"
                  title="待收借条数"
                  :num="dataForm.countByUnReceived"
                ></my-data-panel>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getLendInfo } from "@/api/digit";

export default {
  name: "LendInfo",
  data() {
    return {
      dataForm: {
        countAllByLend: 0,
        countByApplyDelay: 0,
        countByUnReceived: 0,
        lendAllMoney: 0,
        receiveMoney: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getLendInfo().then((res) => {
        Object.assign(this.dataForm, res.data);
      });
    },
  },
};
</script>

<style lang="less">
.lend-info {
}
</style>
